import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Initial state
const initialState = {
  isAuthenticated: false,
  user: null,
};

// Reducer function - manages the authentication state
function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}

// Create Context
const AuthContext = createContext();

// Auth Provider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  // Effect to persist login state using localStorage
  useEffect(() => {
    // const userSession = Cookies.get('userSession');
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      dispatch({ type: 'LOGIN', payload: { isAuthenticated: true, user } });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext - provides the authentication state and actions
export const useAuth = () => useContext(AuthContext);
