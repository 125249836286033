import * as React from 'react';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
} from '@mui/material';
import { AccountCircle, Logout, ExpandMore } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQueryContext } from '../../context/MediaQueryProvider';
import { useAuth } from '../../context/AuthContext';
import apiClient from '../../api/apiClient';
import ENDPOINTS from '../../api/endpoints';
import { useNavigate } from 'react-router-dom';
import { Avatar, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const isMobile = useMediaQueryContext();
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogout = async () => {
    console.log('state of login', state);
    try {
      console.log('logging out');
      await apiClient.get(ENDPOINTS.LOGOUT);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
      navigate('/signin');
      dispatch({
        type: 'LOGOUT',
      });
      handleClose();
    } catch (error) {
      console.error('unable to logout');
    }
  };

  const links = [
    { title: 'Product', path: '/products' },
    // { title: 'Services', path: '/services' },
    { title: 'Blogs', path: '/blogs' },
    { title: 'About', path: '/about' },
  ];

  const handleProfileClick = () => {
    navigate('/profile');
    handleClose();
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'inherit' }}>
        <Toolbar>
          {isMobile ? (
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerToggle}
                sx={{
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    boxSizing: 'circle',
                    borderRadius: '10px', // round edges
                    padding: 2,
                    backgroundColor: '#f9d9d2',
                    height: '90vh', // 50% of the viewport height
                    top: '48px', // Align Drawer below the Navbar
                    overflowY: 'auto',
                    opacity: 0.7,
                  },
                }}
              >
                <List>
                  {links.map((link) => (
                    <ListItemButton
                      key={link.title}
                      onClick={handleDrawerToggle}
                      href={link.path}
                    >
                      <ListItemText primary={link.title} />
                    </ListItemButton>
                  ))}
                </List>
              </Drawer>
              <Link href="/">
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/knoloom_home_logo.png`}
                    alt="Logo"
                    style={{ height: '50px' }}
                  />
                </Box>
              </Link>

              {state.isAuthenticated ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/signin')}
                >
                  Login
                </Button>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                mx: 10,
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Link href="/">
                <Box sx={{ textAlign: 'left' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/knoloom_home_logo.png`}
                    alt="Logo"
                    style={{ height: '50px' }}
                  />
                </Box>
              </Link>

              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {links.map((link) => (
                  <Button key={link.title} href={link.path}>
                    {link.title}
                  </Button>
                ))}
              </div>
              {state.isAuthenticated ? (
                <>
                  <div style={{ margin: 'auto' }}>
                    {/* Avatar with dropdown trigger */}
                    <IconButton
                      onClick={handleClick}
                      size="large"
                      sx={{
                        height: '40px',
                        width: '72px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                        borderRadius: '32px',
                      }}
                    >
                      <Avatar
                        sx={{ bgcolor: 'slateblue', width: 40, height: 40 }}
                      >
                        {state.user.firstName?.charAt(0).toUpperCase() +
                          state.user.lastName?.charAt(0).toUpperCase()}
                      </Avatar>
                      <ExpandMore sx={{ color: 'black', marginLeft: '8px' }} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      sx={{ padding: '10px', borderRadius: '8px' }}
                    >
                      {/* Menu items */}
                      <MenuItem onClick={handleProfileClick}>
                        <ListItemIcon>
                          <AccountCircle fontSize="small" />
                        </ListItemIcon>
                        Profile
                      </MenuItem>
                      <Divider />

                      <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/signin')}
                    sx={{
                      borderRadius: '32px',
                      height: '40px',
                      margin: 'auto',
                    }}
                  >
                    Login
                  </Button>
                </>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Navbar;
