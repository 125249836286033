// src/About.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Avatar,
  Card,
  CardContent,
} from '@mui/material';
import { motion } from 'framer-motion'
import { styled } from '@mui/system';

const MotionBox = motion(Box);

const Section = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const TeamCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(2),
}));

const TeamAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  marginRight: theme.spacing(2),
}));

const About = () => {
  const teamMembers = [
    {
      name: 'Rakesh Shah',
      role: 'Founder',
      description:
        'Rakesh is the visionary founder with over 20 years of experience. He is passionate about creating impactful solutions for humanity.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      name: 'Laxman Kumar',
      role: 'Co-Founder',
      description:
        'Laxman is the co-founder and leads the company’s technical innovations. Her creativity and strategic vision are unmatched.',
      imageUrl: 'https://via.placeholder.com/150',
    },
    {
      name: 'Satya Ranjan',
      role: 'Co-Founder',
      description:
        'Satya is the co-founder and leads the company’s technical innovations. Her creativity and strategic vision are unmatched.',
      imageUrl: 'https://via.placeholder.com/150',
    },
  ];

  return (
    <Container>
      {/* Mission and Vision Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          {/* <Typography variant="h2" align="center" gutterBottom>
            About Us
          </Typography> */}
          <Typography variant="h4" align="center" gutterBottom>
            We are on a Mission
          </Typography>
          <Typography variant="body1" align="left" paragraph>
          Our mission is to unlock the boundless potential within every child. We believe that every kid is a unique, curious, and imaginative individual, and it's our goal to nurture their growth through engaging, educational, and fun activities.
          </Typography>
          <Box textAlign="center">
            <img
              src="https://via.placeholder.com/600x300"
              alt="Company Logo"
              style={{ maxWidth: '100%', marginTop: 20 }}
            />
          </Box>
          <br /> <br />
          <Typography variant="h4" align="center" gutterBottom>
            Our Vision
          </Typography>
          <Typography variant="body1" align="left" paragraph>
          We envision a world where children have access to a rich tapestry of learning experiences, fostering their creativity, critical thinking, and love for exploration. By providing a diverse range of activities, we aim to ignite their curiosity, broaden their horizons, and empower them to become lifelong learners.
          </Typography>
        </MotionBox>
      </Section>

      {/* Goals Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ type: 'spring', stiffness: 50, duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" gutterBottom>
            Our Goals
          </Typography>
          <Box component="ul" sx={{ paddingLeft: '20px', listStyle: 'disc' }}>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              viewport={{ once: true }}
            >
              <b>Encourage Exploration and Discovery: </b><br/> We strive to create an environment where kids can freely explore their interests, ask questions, and discover new passions. Our activities are designed to spark their imagination and stimulate their natural curiosity.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
            >
              <b>Promote Holistic Development:</b><br/> We believe in nurturing the whole child – their cognitive, physical, social, and emotional well-being. Our activities cater to various developmental areas, ensuring a well-rounded growth experience.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              viewport={{ once: true }}
            >
              <b>Foster Creativity and Innovation:</b><br/> In a rapidly changing world, we recognize the importance of cultivating creativity and innovative thinking. Our activities encourage kids to think outside the box, embrace their unique perspectives, and develop problem-solving skills.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              <b>Inspire a Love for Learning:</b> <br/> We aim to instill a lifelong love for learning in every child. By making learning fun, engaging, and relevant to their interests, we hope to spark a passion that will continue to fuel their curiosity and thirst for knowledge.
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              viewport={{ once: true }}
            >
              <b>Empower Parents and Caregivers:</b><br/> We understand the importance of parental and caregiver involvement in a child's development. Our platform provides resources, tools, and guidance to support and empower families in nurturing their children's growth.
            </motion.li>
          </Box>
        </MotionBox>
      </Section>

      {/* Team Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" gutterBottom>
            Meet the Team
          </Typography>
          <Grid container spacing={3}>
            {teamMembers.map((member) => (
              <Grid item xs={12} md={6} key={member.name}>
                <TeamCard>
                  <TeamAvatar src={member.imageUrl} alt={member.name} />
                  <CardContent>
                    <Typography variant="h6">{member.name}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {member.role}
                    </Typography>
                    <Typography variant="body2">
                      {member.description}
                    </Typography>
                  </CardContent>
                </TeamCard>
              </Grid>
            ))}
          </Grid>
        </MotionBox>
      </Section>

      {/* Impact Section */}
      <Section>
        <MotionBox
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <Typography variant="h4" align="center" gutterBottom>
            Our Impact
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Our work inspires kids to explore their creativity and contributes
            to a better world for humanity. Join us on our mission to make a
            positive difference.
          </Typography>
        </MotionBox>
      </Section>
    </Container>
  );
};

export default About;
