import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Navbar from './components/navbar/Navbar';
import withRoot from './pages/withRoot';
import React from 'react';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import NotFound from './pages/NotFound';
import CreateBlog from './pages/CreateBlog';
import Products from './pages/Products';
import ActivityDetailView from './pages/Activity/ActivityDetailView';
import Admin from './pages/Admin';
import { AuthProvider, useAuth } from './context/AuthContext';
import { MediaQueryProvider } from './context/MediaQueryProvider';
import BlogDetailView from './components/blog/BlogDetailView';
import UserProfile from './pages/UserProfile';

const ProtectedRoute = ({ children }) => {
  // const { state } = useAuth();
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  return isAuthenticated ? children : <Navigate to="/signin" replace />;
};

function App() {
  return (
    <React.Fragment>
      <AuthProvider>
        <MediaQueryProvider>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/createblog" element={<CreateBlog />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/blogs/:id" element={<BlogDetailView />} />
              <Route path="/products" element={<Products tabValue="2" />} />
              <Route path="/products/:id" element={<ActivityDetailView />} />
              {/* Protected Routes */}
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <Admin />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </MediaQueryProvider>
      </AuthProvider>
    </React.Fragment>
  );
}

export default withRoot(App);
