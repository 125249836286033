import { useAuth } from '../context/AuthContext';
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container,
  Snackbar,
  Alert,
} from '@mui/material';
import Link from '@mui/material/Link';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import apiClient from '../api/apiClient';
import ENDPOINTS from '../api/endpoints';
import { LoginUser } from '../api/authService';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="/">
        KNOLOOM
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = () => {
  const location = useLocation();
  const [isNewUser, setIsNewUser] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { state, dispatch } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for the query parameter 'newUser'
    const queryParams = new URLSearchParams(location.search);
    setIsNewUser(queryParams.get('newUser') === 'true');
  }, [location.search]);

  const handleLogin = async (event) => {
    event.preventDefault();
    const credentials = new FormData(event.currentTarget);
    // await login(data);
    try {
      // Call Login API
      const response = await LoginUser(credentials);

      console.log('SIgn in resp:', response);

      if (response.status === 200) {
        const user = response.data;
        const authToken = Cookies.get('authToken');

        console.log('user data', user, authToken);
        // Set session cookie
        Cookies.set('userSession', JSON.stringify(user), { expires: 1 }); // Expires in 1 day

        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('user', JSON.stringify(user));

        // Dispatch login success and store data in local storage
        dispatch({ type: 'LOGIN', payload: { isAuthenticated: true, user } });

        console.log('navigating to profile');
        // Redirect to profile page
        navigate('/profile');
      } else {
        throw new Error('Invalid Login Response, should be 200');
      }
    } catch (error) {
      dispatch({
        type: 'LOGIN_FAILURE',
        payload: 'Invalid email or password',
      });
      setSnackbarOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {isNewUser && (
          <Typography variant="subtitle1" color="blue" align="center">
            Welcome, please sign in to view your profile!
          </Typography>
        )}
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="emailId"
            label="Email Address"
            name="emailId"
            autoComplete="emailId"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
      {/* {isAuthenticated && <p>Login successful!</p>} */}
      <Copyright sx={{ mt: 8, mb: 4 }} />

      {/* Snackbar for displaying login errors */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {state.error || 'Login failed! Please try again.'}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignIn;
